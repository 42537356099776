export const isBrowser = () => typeof window !== "undefined";

export const getUser = () =>
  isBrowser() && window.localStorage.getItem("hsdfklwyqeruiqweyobxcbxzmbcasjgfdshf")
    ? JSON.parse(window.localStorage.getItem("hsdfklwyqeruiqweyobxcbxzmbcasjgfdshf"))
    : {};

const setUser = user =>
  window.localStorage.setItem("hsdfklwyqeruiqweyobxcbxzmbcasjgfdshf", JSON.stringify(user));

export const handleLogin = ({ email, password }, members) => {
  // if (email === `a@b.com`) {
  //&& password === `p`) {
  if (members.toLowerCase().indexOf(email.toLowerCase()) > -1) {
    return setUser({
      username: email
      // name: `Johnny`,
      // email: `johnny@example.org`
    });
  }

  return false;
};

export const isLoggedIn = () => {
  const user = getUser();

  return !!user.username;
};

export const logout = callback => {
  setUser({});
  callback();
};
